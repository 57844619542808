.select2-container {
  display: block; }
  .select2-container *:focus {
    outline: 0; }

.input-group .select2-container--bootstrap4 {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .select2-container--bootstrap4:not(:last-child) .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--bootstrap4 .select2-selection {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .select2-container--bootstrap4 .select2-selection {
      -webkit-transition: none;
      transition: none; } }

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: #80bdff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.select2-container--bootstrap4.select2-container--focus.select2-container--open .select2-selection {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.select2-container--bootstrap4.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--bootstrap4.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.select2-container--bootstrap4.select2-container--disabled .select2-selection, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-selection {
  cursor: not-allowed;
  background-color: #e9ecef;
  border-color: #ced4da;
  -webkit-box-shadow: none;
          box-shadow: none; }

.select2-container--bootstrap4.select2-container--disabled .select2-search__field, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-search__field {
  background-color: transparent; }

select.is-invalid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated select:invalid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #dc3545; }

select.is-valid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated select:valid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #28a745; }

.select2-container--bootstrap4 .select2-search {
  width: 100%; }

.select2-container--bootstrap4 .select2-dropdown {
  border-color: #ced4da;
  border-radius: 0; }
  .select2-container--bootstrap4 .select2-dropdown.select2-dropdown--below {
    border-top: none;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
    border-top: 1px solid #ced4da;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .select2-container--bootstrap4 .select2-dropdown .select2-results__option[aria-selected="true"] {
    color: #212529;
    background-color: #f2f2f2; }

.select2-container--bootstrap4 .select2-results__option--highlighted,
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
  color: #fff;
  background-color: #007bff; }

.select2-container--bootstrap4 .select2-results__option[role="group"] {
  padding: 0; }
  .select2-container--bootstrap4 .select2-results__option[role="group"] .select2-results__options--nested .select2-results__option {
    padding-left: 1em; }

.select2-container--bootstrap4 .select2-results__option {
  padding: 0.375rem 0.75rem; }

.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 15em;
  overflow-y: auto; }

.select2-container--bootstrap4 .select2-results__group {
  display: list-item;
  padding: 6px;
  color: #6c757d; }

.select2-container--bootstrap4 .select2-selection__clear {
  float: right;
  width: .9em;
  height: .9em;
  padding-left: .15em;
  margin-top: .7em;
  margin-right: .3em;
  line-height: .75em;
  color: #f8f9fa;
  background-color: #c8c8c8;
  border-radius: 100%; }
  .select2-container--bootstrap4 .select2-selection__clear:hover {
    background-color: #afafaf; }

.select2-container--bootstrap4 .select2-selection--single {
  height: calc(1.5em + 0.75rem + 2px) !important; }
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
    line-height: calc(1.5em + 0.75rem);
    color: #6c757d; }
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 50%;
    right: 3px;
    width: 20px; }
    .select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
      position: absolute;
      top: 60%;
      left: 50%;
      width: 0;
      height: 0;
      margin-top: -2px;
      margin-left: -4px;
      border-color: #343a40 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0; }
  .select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
    padding-left: 0.75rem;
    line-height: calc(1.5em + 0.75rem);
    color: #495057; }

.select2-search--dropdown .select2-search__field {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.select2-results__message {
  color: #6c757d; }

.select2-container--bootstrap4 .select2-selection--multiple {
  min-height: calc(1.5em + 0.75rem + 2px) !important; }
  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    padding: 0 0.375rem;
    margin: 0;
    list-style: none; }
  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
    float: left;
    padding: 0;
    padding-right: 0.75rem;
    margin-top: calc(0.375rem - 2px);
    margin-right: 0.375rem;
    color: #495057;
    cursor: pointer;
    border: 1px solid #bdc6d0;
    border-radius: 0.2rem; }
  .select2-container--bootstrap4 .select2-selection--multiple .select2-search__field {
    color: #495057; }
  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice + .select2-search {
    width: 0; }
  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
    float: left;
    padding-right: 3px;
    padding-left: 3px;
    margin-right: 1px;
    margin-left: 3px;
    font-weight: 700;
    color: #bdc6d0; }
    .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #343a40; }
  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
    position: absolute !important;
    top: 0;
    right: .7em;
    float: none;
    margin-right: 0; }

.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  padding: 0 5px;
  cursor: not-allowed; }
  .select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
    display: none; }

